// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box, Toolbar } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import HamburgerMenu from './components/HamburgerMenu';
import TopUpPage from './pages/TopUpPage';
import BattleLobbyPage from './pages/BattleLobbyPage';
import ConfirmPage from './pages/ConfirmPage';
import CancelPage from './pages/CancelPage';
import GamePage from './pages/GamePage';
import InvitePage from './pages/InvitePage';
import FriendListPage from './pages/FriendListPage';
import darkTheme from './components/background/theme';
import GlobalBackground from './components/GlobalBackground';
import { SignalRProvider } from './contexts/SignalRContext';
import { FriendSignalRProvider } from './contexts/FriendSignalRContext';
import LiffProvider from './contexts/LiffContext'; // 确保导入正确

function App() {
  return (
    <LiffProvider>
      <SignalRProvider>
        <FriendSignalRProvider>
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <GlobalBackground />
            <Router>
              <Box sx={{ display: 'flex' }}>
                <HamburgerMenu />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                  <Toolbar />
                  <Routes>
                    <Route path="/topup" element={<TopUpPage />} />
                    <Route path="/" element={<BattleLobbyPage />} />
                    <Route path="/confirm" element={<ConfirmPage />} />
                    <Route path="/cancel" element={<CancelPage />} />
                    <Route path="/game/:roomId" element={<GamePage />} />
                    <Route path="/invite" element={<InvitePage />} />
                    <Route path="/friends" element={<FriendListPage />} />
                  </Routes>
                </Box>
              </Box>
            </Router>
          </ThemeProvider>
        </FriendSignalRProvider>
      </SignalRProvider>
    </LiffProvider>
  );
}

export default App;

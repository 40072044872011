// src/pages/CancelPage.js

import React from 'react';
import { Typography, Container, Box, Alert } from '@mui/material';

const CancelPage = () => {
  return (
    <Container maxWidth="sm" sx={{ mt: 12 }}>
      <Box sx={{ textAlign: 'center' }}>
        <Alert severity="warning">
          您已取消儲值操作。
        </Alert>
      </Box>
    </Container>
  );
};

export default CancelPage;

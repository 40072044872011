// src/pages/InvitePage.js

import React, { useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import { LiffContext } from '../contexts/LiffContext';
import { apiUrl } from '../apiUrl';

const InvitePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLiffInitialized, liff, liffError, profile, information } = useContext(LiffContext);

  useEffect(() => {
    const handleInvite = async () => {
      if (!isLiffInitialized) {
        console.log('LIFF 尚未初始化');
        return;
      }

      if (liffError) {
        console.error('LIFF 初始化錯誤:', liffError);
        navigate('/');
        return;
      }

      const { referrer } = queryString.parse(location.search);

      if (!liff.isLoggedIn()) {
        await liff.login();
      }

      const userProfile = await liff.getProfile();
      const inviteeUserId = userProfile.userId;

      if (referrer && inviteeUserId) {
        try {
          const response = await axios.post(`${apiUrl}/invite`, {
            referrerUserId: referrer,
            inviteeUserId: inviteeUserId,
          });

          if (response.status === 200) {
            if (response.data.source) {
              const roomId = response.data.source;
              navigate(`/game/${roomId}`);
            } else if (response.data.errors && response.data.errors.length > 0) {
              // 后端返回错误信息
              console.error('後端錯誤:', response.data.errors);
              navigate('/');
            }
          }
        } catch (error) {
          console.error('Error joining room:', error);
          navigate('/');
        }
      } else {
        navigate('/');
      }
    };

    handleInvite();
  }, [isLiffInitialized, liff, liffError, location.search, navigate]);

  return (
    <div>
      {/* 添加加载状态 */}
      {!isLiffInitialized && <p>載入中...</p>}
      {isLiffInitialized && !liffError && <p>處理邀請中...</p>}
      {liffError && <p>初始化失敗</p>}
    </div>
  );
};

export default InvitePage;

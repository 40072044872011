import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import './index.css';
import App from './App';
import { useNavigate, useLocation } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

axios.interceptors.request.use(config => {
  const userId = localStorage.getItem('userId');
  if (userId) {
    config.headers.Authorization = `${userId}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const { status, data } = error.response;
      if (status === 400) {
        const errorDetails = data;
        if (errorDetails && errorDetails.errors) {
          alert(`Error: ${errorDetails.errors}`);
        } else {
          alert(`${errorDetails}`);
        }
        window.location.href = 'https://liff.line.me/2006324027-ELngnp3Z';
      } else if (status === 401) {
        alert('請先綁定官方帳號與網站帳號!');
        window.location.href = 'https://lucky-egg.club/login';
 
      } else if (status === 500) {
        const errorDetails = data;
        const errorMessage = errorDetails.Message || 'Internal Server Error';
        const innerMessage = errorDetails.InnerMessage ? `\nDetails: ${errorDetails.InnerMessage}` : '';
        alert(`Error: ${errorMessage}${innerMessage}`);
      }
    }

    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <App />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// src/contexts/SignalRContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import * as signalR from '@microsoft/signalr';
import { friendSocketUrl } from '../friendSocketUrl';
import { LiffContext } from './LiffContext';

const SignalRContext = createContext();

export const useSignalR = () => {
  return useContext(SignalRContext);
};

export const FriendSignalRProvider = ({ children }) => {
  const [connection, setConnection] = useState(null);
  const { profile } = useContext(LiffContext);
  const currentUserId = profile?.userId;

  useEffect(() => {
    if (currentUserId) {
        alert('socketFriend')
      const newConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${friendSocketUrl}?userId=${currentUserId}`)
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

      newConnection.start()
        .then(() => {
          console.log('Connected to PresenceHub via Context');
          setConnection(newConnection);
        })
        .catch(err => console.error('Error connecting to PresenceHub:', err));

      return () => {
        if (newConnection) {
          newConnection.stop();
        }
      };
    }
  }, [currentUserId]);

  return (
    <SignalRContext.Provider value={connection}>
      {children}
    </SignalRContext.Provider>
  );
};

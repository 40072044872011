// src/pages/GamePage.js
import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Typography,
  Container,
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Snackbar,
  Alert,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSignalR } from '../contexts/SignalRContext';
import { apiUrl } from '../apiUrl';
import { LiffContext } from '../contexts/LiffContext';
import { keyframes } from '@emotion/react';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Marquee from 'react-fast-marquee';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const cardImages = {
  1: '/images/皇帝.jpg', // 皇帝
  2: '/images/乞丐.jpg', // 奴隸
  3: '/images/平民.jpg', // 平民
};

const cardNames = {
  1: '皇帝',
  2: '奴隸',
  3: '平民',
};

const cardBackImage = '/images/蓋牌.jpg'; // 卡背圖片

// 定義遊戲狀態枚舉
const GAME_STATUS = {
  ENDED: -1,
  WAITING: 0,
  IN_PROGRESS: 1,
  COUNTDOWN: 2,
  UNKNOWN: 4,
};

const GamePage = () => {
  const {
    isLiffInitialized,
    liff,
    liffError,
    profile,
    information,
  } = useContext(LiffContext);
  const { roomId } = useParams();
  const navigate = useNavigate();
  const connection = useSignalR();
  const [room, setRoom] = useState(null);
  const [playerHand, setPlayerHand] = useState([]);
  const [playedCards, setPlayedCards] = useState({});
  const [gameStatus, setGameStatus] = useState(GAME_STATUS.UNKNOWN);
  const [gameStartCountdown, setGameStartCountdown] = useState(null);
  const [roundCountdown, setRoundCountdown] = useState(null);
  const [hasPlayedCard, setHasPlayedCard] = useState(false);
  const [showPlayedCards, setShowPlayedCards] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [isRoomOwner, setIsRoomOwner] = useState(false); // 是否為房主
  const [winnerName, setWinnerName] = useState(''); // 贏家名稱

  const eventHandlersAttached = useRef(false); // 用於確保事件監聽器只綁定一次

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (connection && connection.state === 'Connected' && !eventHandlersAttached.current) {
      eventHandlersAttached.current = true; // 標記為已綁定

      // 加入房間
      connection
        .invoke('JoinRoom', roomId, profile.userId)
        .catch((err) => console.error('JoinRoom error:', err));

      // 監聽遊戲事件
      connection.on('UserJoined', (userName, userId) => {
        setSnackbarMessage(`${userName} 加入了房間。`);
        setSnackbarSeverity('info');
        setSnackbarOpen(true);
      });

      connection.on('GameStarted', (initialCards) => {
        setPlayerHand(initialCards[profile.userId] || []);
        setGameStatus(GAME_STATUS.IN_PROGRESS);
        setRoundCountdown(10);
      });

      connection.on('GameStarting', (countdown) => {
        setGameStatus(GAME_STATUS.COUNTDOWN);
        setGameStartCountdown(countdown);
      });

      connection.on('CountdownUpdate', (countdown) => {
        setGameStartCountdown(countdown);
      });

      connection.on('CardPlayed', (userId, cardType) => {
        setPlayedCards((prev) => ({ ...prev, [userId]: cardType }));
      });

      connection.on(
        'RoundResult',
        (playedCards, winnerUserId, loserUserId) => {
          setPlayedCards(playedCards);
          setShowPlayedCards(true);
          setHasPlayedCard(false);
          setRoundCountdown(10);
          if (winnerUserId && loserUserId) {
            // 遊戲結束
            setGameStatus(GAME_STATUS.ENDED);
            setWinnerName(
              room.players.find((p) => p.userId === winnerUserId)?.userName ||
                ''
            );
            setTimeout(() => {
              navigate('/');
            }, 5000);
          } else {
            // 平局，繼續下一回合
            setSnackbarMessage('平局，繼續下一回合。');
            setSnackbarSeverity('info');
            setSnackbarOpen(true);
          }
        }
      );

      connection.on('GameEnded', (winnerUserId, loserUserId) => {
        setGameStatus(GAME_STATUS.ENDED);
        setWinnerName(
          room.players.find((p) => p.userId === winnerUserId)?.userName || ''
        );
        setTimeout(() => {
          navigate('/');
        }, 5000);
      });

      connection.on('RoomUpdated', (updatedRoom) => {
        setRoom(updatedRoom);
        // 檢查是否為房主
        if (updatedRoom.ownerUserId === profile.userId) {
          setIsRoomOwner(true);
        } else {
          setIsRoomOwner(false);
        }

        // 更新遊戲狀態
        switch (updatedRoom.status) {
          case -1:
            setGameStatus(GAME_STATUS.ENDED);
            break;
          case 0:
            setGameStatus(GAME_STATUS.WAITING);
            break;
          case 1:
            setGameStatus(GAME_STATUS.IN_PROGRESS);
            break;
          case 2:
            setGameStatus(GAME_STATUS.COUNTDOWN);
            break;
          default:
            setGameStatus(GAME_STATUS.UNKNOWN);
        }
      });

      connection.on('RoomDeleted', (message) => {
        setSnackbarMessage(message);
        setSnackbarSeverity('info');
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate('/');
        }, 3000);
      });

      connection.on('UserLeft', (userName) => {
        setSnackbarMessage(`${userName} 已離開房間`);
        setSnackbarSeverity('info');
        setSnackbarOpen(true);
      });

      connection.on('Error', (message) => {
        setSnackbarMessage(message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        // 如果有错误，返回大厅
        setTimeout(() => {
          navigate('/');
        }, 2000);
      });

      // Cleanup on unmount
      return () => {
        eventHandlersAttached.current = false; // 重置標記
        if (connection) {
          connection.off('UserJoined');
          connection.off('GameStarted');
          connection.off('GameStarting');
          connection.off('CountdownUpdate');
          connection.off('CardPlayed');
          connection.off('GameEnded');
          connection.off('RoundResult');
          connection.off('RoomUpdated');
          connection.off('RoomDeleted');
          connection.off('UserLeft');
          connection.off('Error');
        }
      };
    }
  }, [connection, roomId, profile.userId, navigate]);

  useEffect(() => {
    // 獲取房間信息
    fetchRoom();
  }, [roomId]);

  const fetchRoom = async () => {
    try {
      const response = await axios.get(`${apiUrl}/room?roomId=${roomId}`);
      if (response.status === 200 && response.data.source) {
        setRoom(response.data.source);
        const player = response.data.source.players.find(
          (p) => p.userId === profile.userId
        );
        setPlayerHand(player?.handCards || []);

        // 設置遊戲狀態
        switch (response.data.source.status) {
          case -1:
            setGameStatus(GAME_STATUS.ENDED);
            break;
          case 0:
            setGameStatus(GAME_STATUS.WAITING);
            break;
          case 1:
            setGameStatus(GAME_STATUS.IN_PROGRESS);
            break;
          case 2:
            setGameStatus(GAME_STATUS.COUNTDOWN);
            break;
          default:
            setGameStatus(GAME_STATUS.UNKNOWN);
        }

        // 檢查是否為房主
        if (response.data.source.ownerUserId === profile.userId) {
          setIsRoomOwner(true);
        } else {
          setIsRoomOwner(false);
        }
      }
    } catch (error) {
      console.error('獲取房間信息錯誤:', error);
      setSnackbarMessage('無法獲取房間信息。');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      navigate('/');
    }
  };

  // 每回合倒計時
  useEffect(() => {
    let timer;
    if (
      gameStatus === GAME_STATUS.IN_PROGRESS &&
      roundCountdown !== null &&
      roundCountdown > 0
    ) {
      timer = setTimeout(() => {
        setRoundCountdown((prev) => prev - 1);
      }, 1000);
    } else if (roundCountdown === 0) {
      setRoundCountdown(null);
      if (!hasPlayedCard) {
        // 超時自動出牌
        if (playerHand.length > 0) {
          handlePlayCard(playerHand[0]);
        }
      }
    }
    return () => clearTimeout(timer);
  }, [roundCountdown, gameStatus, hasPlayedCard, playerHand]);

  // 檢查是否雙方都已出牌
  useEffect(() => {
    if (
      room &&
      Object.keys(playedCards).length === room.players.length &&
      gameStatus === GAME_STATUS.IN_PROGRESS
    ) {
      // 顯示雙方出的牌
      setShowPlayedCards(true);
      // 5秒後開始下一回合
      setTimeout(() => {
        setShowPlayedCards(false);
        setPlayedCards({});
        setHasPlayedCard(false);
        setRoundCountdown(10);
      }, 5000);
    }
  }, [playedCards, room, gameStatus]);

  const handlePlayCard = async (cardType) => {
    if (hasPlayedCard) return; // 防止重複出牌
    if (connection && connection.state === 'Connected') {
      try {
        await connection.invoke('PlayCard', roomId, profile.userId, cardType);
        setPlayerHand((prev) => prev.filter((card) => card !== cardType));
        setHasPlayedCard(true);
      } catch (error) {
        console.error('出牌錯誤:', error);
        setSnackbarMessage('出牌失敗，請重試。');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  const handleLeaveRoom = async () => {
    if (connection && connection.state === 'Connected') {
      try {
        await connection.invoke('LeaveRoom', roomId, profile.userId);
        connection.stop(); // 斷開連接
        navigate('/'); // 返回大廳
      } catch (error) {
        console.error('離開房間錯誤:', error);
        setSnackbarMessage('離開房間失敗，請重試。');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  if (!room) {
    return (
      <Container>
        <Typography variant="h6">載入中...</Typography>
      </Container>
    );
  }

  // 獲取對手信息
  const opponent = room.players.find((p) => p.userId !== profile.userId);

  // 定義遊戲狀態文字
  const getGameStatusText = () => {
    switch (gameStatus) {
      case GAME_STATUS.WAITING:
        return '等待中';
      case GAME_STATUS.IN_PROGRESS:
        return '遊戲中';
      case GAME_STATUS.ENDED:
        return '已結束';
      case GAME_STATUS.COUNTDOWN:
        return '即將開始';
      default:
        return '未知狀態';
    }
  };

  // 定義倒計時動畫
  const countdownAnimation = keyframes`
    from { transform: scale(1); opacity: 1; }
    to { transform: scale(1.5); opacity: 0; }
  `;

  return (
    <Container maxWidth="sm">
      <Box my={2}>
        <Typography variant="h5" gutterBottom>
          房間: {room.roomName}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          狀態: {getGameStatusText()}
        </Typography>

        {/* 倒計時畫面 */}
        {gameStatus === GAME_STATUS.COUNTDOWN && (
          <Box
            width="100%"
            height="50vh"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            bgcolor="#1e1e1e"
            color="#fff"
            py={2}
          >
            <Typography
              variant="h2"
              sx={{
                animation: `${countdownAnimation} 1s infinite`,
              }}
            >
              {gameStartCountdown}
            </Typography>
          </Box>
        )}

        {/* 等待中畫面 */}
        {gameStatus === GAME_STATUS.WAITING && (
          <Box
            width="100%"
            height="50vh"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            bgcolor="#1e1e1e"
            color="#fff"
            py={2}
          >
            <Marquee gradient={false} speed={50} style={{ fontSize: '1.5rem', color: '#fff' }}>
              🎉 等待其他玩家加入... 🎲
            </Marquee>
          </Box>
        )}

        {/* 顯示每回合倒計時 */}
        {roundCountdown !== null && gameStatus === GAME_STATUS.IN_PROGRESS && (
          <Typography variant="h5" align="center" gutterBottom>
            倒數計時: {roundCountdown}
          </Typography>
        )}

        {/* 顯示對手的手牌 */}
        {gameStatus === GAME_STATUS.IN_PROGRESS && opponent && (
          <Box>
            <Typography variant="h6" gutterBottom>
              對手的手牌 ({opponent?.userName || '未知'}):
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              {opponent.handCards.map((_, index) => (
                <Grid item key={index}>
                  <Card sx={{ width: isSmallScreen ? 80 : 100 }}>
                    <CardMedia
                      component="img"
                      height={isSmallScreen ? 100 : 140}
                      image={cardBackImage} // 卡背圖片
                      alt="對手的牌"
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {/* 顯示玩家的手牌 */}
        {(gameStatus === GAME_STATUS.IN_PROGRESS ||
          gameStatus === GAME_STATUS.WAITING ||
          gameStatus === GAME_STATUS.COUNTDOWN) && (
          <Box mt={2}>
            <Typography variant="h6" gutterBottom>
              你的手牌 ({profile.displayName}):
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              {playerHand.map((card, index) => (
                <Grid item key={index}>
                  <IconButton
                    onClick={
                      !hasPlayedCard && gameStatus === GAME_STATUS.IN_PROGRESS
                        ? () => handlePlayCard(card)
                        : null
                    }
                    sx={{
                      cursor:
                        hasPlayedCard || gameStatus !== GAME_STATUS.IN_PROGRESS
                          ? 'not-allowed'
                          : 'pointer',
                      padding: 0,
                    }}
                    disabled={hasPlayedCard || gameStatus !== GAME_STATUS.IN_PROGRESS}
                  >
                    <Card
                      sx={{
                        width: isSmallScreen ? 80 : 100,
                      }}
                    >
                      <CardMedia
                        component="img"
                        height={isSmallScreen ? 100 : 140}
                        image={cardImages[card]} // 使用卡片圖片路徑
                        alt={cardNames[card]}
                      />
                      <CardContent>
                        <Typography variant="body2" color="text.secondary" align="center">
                          {cardNames[card]}
                        </Typography>
                      </CardContent>
                    </Card>
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {/* 顯示已出牌 */}
        {showPlayedCards && (
          <Box
            position="fixed"
            top={0}
            left={0}
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="rgba(0,0,0,0.5)"
            zIndex={9999}
          >
            <Grid container spacing={4} justifyContent="center">
              {Object.entries(playedCards).map(([userId, cardType]) => (
                <Grid item key={userId}>
                  <Card sx={{ width: isSmallScreen ? 120 : 150 }}>
                    <CardMedia
                      component="img"
                      height={isSmallScreen ? 160 : 200}
                      image={cardImages[cardType]}
                      alt={cardNames[cardType]}
                    />
                    <CardContent>
                      <Typography
                        variant="h6"
                        color="text.secondary"
                        align="center"
                      >
                        {userId === profile.userId ? '你' : '對手'}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {/* 遊戲結束時顯示贏家信息 */}
        {gameStatus === GAME_STATUS.ENDED && (
          <Box
            position="fixed"
            top={0}
            left={0}
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            bgcolor="rgba(0,0,0,0.8)"
            zIndex={9999}
          >
            <EmojiEventsIcon style={{ fontSize: 80, color: '#FFD700' }} />
            <Marquee
              gradient={false}
              speed={50}
              style={{ width: '100%', overflow: 'hidden', marginTop: 20 }}
            >
              <Typography variant="h5" style={{ color: '#fff' }}>
                恭喜贏家 {winnerName}
              </Typography>
            </Marquee>
          </Box>
        )}

        {/* 美化提示信息 */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>

      {/* 固定在右下角的離開房間按鈕 */}
      <IconButton
        color="secondary"
        onClick={handleLeaveRoom}
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        <ExitToAppIcon fontSize="large" />
      </IconButton>
    </Container>
  );
};

export default GamePage;

// src/pages/FriendListPage.js

import React, { useEffect, useState, useContext } from 'react';
import {
  Typography,
  Grid,
  Paper,
  Badge,
  Avatar,
  CircularProgress,
  Box,
  Button,
  Modal,
  TextField,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import { apiUrl } from '../apiUrl';
import { styled } from '@mui/system';
import { LiffContext } from '../contexts/LiffContext';
import { useSignalR } from '../contexts/FriendSignalRContext';
import Picker from 'emoji-picker-react';
import CloseIcon from '@mui/icons-material/Close';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#1e1e1e',
  color: '#fff',
  borderRadius: '10px',
  textAlign: 'center',
  position: 'relative',
  overflow: 'hidden',
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 4px 20px rgba(255, 255, 255, 0.2)',
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 80,
  height: 80,
  margin: '0 auto',
  border: `2px solid ${theme.palette.primary.main}`,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#333',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#555',
  },
  marginTop: theme.spacing(1),
}));

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent = styled(Paper)(({ theme }) => ({
  backgroundColor: '#1e1e1e',
  padding: theme.spacing(4),
  outline: 'none',
  borderRadius: '10px',
  position: 'relative',
  width: '400px',
  color: '#fff',
}));

const FriendListPage = () => {  
  const [friends, setFriends] = useState([]);
  const [onlineFriends, setOnlineFriends] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { profile, liff } = useContext(LiffContext);
  const currentUserId = profile?.userId;
  const connection = useSignalR();

  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedFriend, setSelectedFriend] = useState(null);

  const handleOpenModal = (friend) => {
    setSelectedFriend(friend);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setMessage('');
    setSelectedFriend(null);
  };

  const handleSendMessage = async () => {
    if (!selectedFriend) return;

    try {
      if (!liff.isLoggedIn()) {
        await liff.login({
          scope: ['profile', 'openid', 'email', 'share_target'],
          prompt: 'consent',
        });
      }

      const messageToSend = {
        type: 'text',
        text: message || `${profile.displayName} 邀請你一起玩遊戲！`,
      };

      await liff.shareTargetPicker([messageToSend]);
      alert('訊息已發送！');
      handleCloseModal();
    } catch (error) {
      console.error('Error sending message:', error);
      alert('發送訊息失敗，請重試。');
    }
  };

  const onEmojiClick = (emojiObject) => {
    setMessage((prevMessage) => prevMessage + emojiObject.emoji);
  };

  useEffect(() => {
    let isMounted = true;

    const fetchFriends = async () => {
      try {
        // 使用 API 獲取好友列表
        const response = await axios.get(`${apiUrl}/friends`, {
          params: { userId: currentUserId },
        });
        if (!isMounted) return;
        setFriends(response.data.source);
        alert(JSON.stringify(response.data, null, 2));
        const friendIds = response.data.source.map((friend) => friend.lineUserId.toString());   
        // 在連接建立後，處理在線好友
        if (connection && connection.state === 'Connected') {
          
          connection
            .invoke('GetOnlineFriends', friendIds)
            .then((onlineFriendIds) => {
              if (!isMounted) return;
              setOnlineFriends(onlineFriendIds);
            })
            .catch((err) => console.error('Error invoking GetOnlineFriends:', err));

          // 處理好友的上線和下線事件
          const handleUserIsOnline = (userId) => {
            if (friendIds.includes(userId)) {
              setOnlineFriends((prev) => [...prev, userId]);
            }
          };

          const handleUserIsOffline = (userId) => {
            if (friendIds.includes(userId)) {
              setOnlineFriends((prev) => prev.filter((id) => id !== userId));
            }
          };

          connection.on('UserIsOnline', handleUserIsOnline);
          connection.on('UserIsOffline', handleUserIsOffline);

          // Cleanup listeners on unmount or dependency change
          return () => {
            connection.off('UserIsOnline', handleUserIsOnline);
            connection.off('UserIsOffline', handleUserIsOffline);
          };
        }
      } catch (err) {
        console.error('獲取好友列表失敗:', err);
        if (!isMounted) return;
        alert(err)
        setError('無法獲取好友列表，請稍後再試。');
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    if (currentUserId && connection) {
      if (connection.state === 'Disconnected') {
        connection
          .start()
          .then(() => {
            console.log('SignalR connected');
            fetchFriends();
          })
          .catch((err) => console.error('SignalR connection error:', err));
      } else {
        fetchFriends();
      }
    }

    return () => {
      isMounted = false;
      if (connection) {
        connection.off('UserIsOnline');
        connection.off('UserIsOffline');
      }
    };
  }, [currentUserId, connection]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
          backgroundColor: '#121212',
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          padding: 4,
          backgroundColor: '#121212',
          minHeight: '100vh',
        }}
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 4, backgroundColor: '#121212', minHeight: '100vh' }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ color: '#fff', textAlign: 'center', mb: 4 }}
      >
        好友列表
      </Typography>
      <Grid container spacing={4}>
        {friends.map((friend) => {
          const isOnline = onlineFriends.includes(friend.lineUserId.toString());
          return (
            <Grid item xs={12} sm={6} md={4} key={friend.lineUserId}>
              <StyledPaper>
                <Badge
                  color="success"
                  overlap="circular"
                  badgeContent=" "
                  variant={isOnline ? 'dot' : undefined}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <StyledAvatar alt={friend.name} src={friend.headShotUrl} />
                </Badge>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  {friend.nickName}
                </Typography>
                <Typography variant="body2" sx={{ color: '#bbb' }}>
                  {friend.email}
                </Typography>
                <Typography variant="body2" sx={{ color: '#bbb', mb: 2 }}>
                  {friend.statusMessage}
                </Typography>
                <StyledButton variant="contained" onClick={() => handleOpenModal(friend)}>
                  發送 LINE 訊息
                </StyledButton>
              </StyledPaper>
            </Grid>
          );
        })}
      </Grid>

      {/* 彈出視窗 */}
      <StyledModal open={openModal} onClose={handleCloseModal}>
        <ModalContent>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#fff',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ mb: 2 }}>
            發送訊息給 {selectedFriend?.name}
          </Typography>
          <TextField
            variant="outlined"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
            sx={{
              '& .MuiInputBase-root': {
                color: '#fff',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#555',
              },
              '& .MuiInputLabel-root': {
                color: '#bbb',
              },
            }}
          />
          <Box sx={{ mt: 2 }}>
            <Picker onEmojiClick={onEmojiClick} theme="dark" />
          </Box>
          <StyledButton variant="contained" onClick={handleSendMessage} fullWidth sx={{ mt: 2 }}>
            發送
          </StyledButton>
        </ModalContent>
      </StyledModal>
    </Box>
  );
};

export default FriendListPage;

// src/pages/BattleLobbyPage.js
import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Typography,
  Button,
  FormControl,
  FormHelperText,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Container,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSignalR } from '../contexts/SignalRContext';
import { apiUrl } from '../apiUrl';
import { LiffContext } from '../contexts/LiffContext';

const BattleLobbyPage = () => {
  const { isLiffInitialized, liff, liffError, profile, information } =useContext(LiffContext);
  const [rooms, setRooms] = useState([]);
  const [roomName, setRoomName] = useState('');
  const [money, setMoney] = useState(0);
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const navigate = useNavigate();
  const connection = useSignalR();

  const eventHandlersAttached = useRef(false); // 用于确保事件监听器只绑定一次

  useEffect(() => {
    if (connection && connection.state === 'Connected' && !eventHandlersAttached.current) {
      eventHandlersAttached.current = true;

      // Fetch initial room list
      fetchRooms();

      // Listen to RoomCreated and RoomUpdated events
      connection.on('RoomCreated', (game) => {
        alert('RoomCreated')
        setRooms((prevRooms) => [...prevRooms, game]);
      });

      connection.on('RoomUpdated', (updatedRoom) => {
        alert('RoomUpdated')
        setRooms((prevRooms) =>
          prevRooms.map((room) => (room.id === updatedRoom.id ? updatedRoom : room))
        );
      });

      connection.on('Error', (message) => {
        setSnackbarMessage(message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });

      // Cleanup on unmount
      return () => {
        eventHandlersAttached.current = false;
        connection.off('RoomCreated');
        connection.off('RoomUpdated');
        connection.off('Error');
      };
    }
  }, [connection]);

  const fetchRooms = async () => {
    try {
      const response = await axios.get(`${apiUrl}/rooms`);
      if (response.status === 200 && response.data.source) {
        alert('${apiUrl}/rooms')
        setRooms(response.data.source);
      }
    } catch (error) {
      console.error('Error fetching rooms:', error);
    }
  };

  const handleCreateRoom = async () => {
    if (!roomName.trim()) {
      setErr(true);
      return;
    }
    setErr(false);
    try {
      const response = await axios.post(`${apiUrl}/room`, {
        roomName,
        userId: profile.userId,
        money,
      });
      setRoomName('');
      const createdRoom = response.data.source;
      if (createdRoom && createdRoom.id) {
        navigate(`/game/${createdRoom.id}`);
      }
    } catch (error) {
      console.error('Error creating room:', error);
      setSnackbarMessage('创建房间失败，请重试。');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleJoinRoom = async (roomId) => {
    if (connection && connection.state === 'Connected') {
      try {
        await connection.invoke('JoinRoom', roomId, profile.userId);
        // 如果成功加入房间，才导航到游戏页面
        navigate(`/game/${roomId}`);
      } catch (error) {
        console.error('Error joining room:', error);
        // 错误信息将通过 Error 事件发送，这里不需要额外处理
      }
    } else {
      setSnackbarMessage('未连接到服务器，请稍后再试。');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          戰鬥大廳
        </Typography>
        <FormControl error={err} fullWidth sx={{ mb: 2 }}>
          <Box display="flex" alignItems="center">
            <TextField
              label="房間名稱"
              variant="outlined"
              value={roomName}
              onChange={(e) => setRoomName(e.target.value)}
            />
            <TextField
              label="賭注"
              style={{ marginLeft: 10 }}
              variant="outlined"
              value={money}
              onChange={(e) => setMoney(e.target.value)}
            />
            <Button
              variant="outlined"
              color="warning"
              onClick={handleCreateRoom}
              sx={{
                ml: 3,
                height: '56px',
                width: '56px',
                minWidth: 'unset',
                padding: 0,
                borderRadius: '50%',
              }}
              disabled={loading}
            >
              <AddIcon fontSize="large" />
            </Button>
          </Box>
          {err && <FormHelperText>不得為空</FormHelperText>}
        </FormControl>
        <Typography variant="h6" gutterBottom>
          房間列表
        </Typography>
        <List>
          {rooms.map((room) => (
            <ListItem key={room.id}>
              <ListItemText
                primary={room.roomName}
                secondary={`房主: ${room.ownerName} 賭金:${room.money}`}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleJoinRoom(room.id)}
              >
                加入
              </Button>
            </ListItem>
          ))}
        </List>
      </Box>
      {/* 提示信息 */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default BattleLobbyPage;

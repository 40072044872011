// src/pages/ConfirmPage.js

import React, { useEffect, useState } from 'react';
import { Typography, Container, CircularProgress, Box, Alert, Paper, Grid, Divider } from '@mui/material';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from '../apiUrl';

const ConfirmPage = () => {
  const location = useLocation();
  const [status, setStatus] = useState('loading'); // 'loading', 'success', 'error'
  const [confirmRes, setConfirmRes] = useState(null);

  useEffect(() => {
    const confirmPayment = async () => {
      const query = new URLSearchParams(location.search);
      const transactionId = query.get('transactionId');
      const orderId = query.get('orderId');

      if (transactionId && orderId) {
        try {
          const response = await axios.post(`${apiUrl}/confirm`, null, {
            params: {
              transactionId,
              orderId,
            },
          });
          if (response.status === 200 && response.data.returnCode === "0000") {
            setConfirmRes(response.data.info);
            setStatus('success');
          } else {
            setStatus('error');
          }
        } catch (error) {
          console.error('API error:', error);
          setStatus('error');
        }
      } else {
        setStatus('error');
      }
    };

    confirmPayment();  // 调用内部的异步函数
  }, [location.search]);

  return (
    <Container maxWidth="sm" sx={{ mt: 12 }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        {status === 'loading' && <CircularProgress />}
        {status === 'success' && (
          <Alert severity="success" sx={{ mb: 2 }}>
            儲值成功！您的金幣餘額已更新。
          </Alert>
        )}
        {status === 'error' && (
          <Alert severity="error">
            儲值失敗，請稍後再試。
          </Alert>
        )}
      </Box>
      
      {status === 'success' && confirmRes && (
        <Paper elevation={3} sx={{ padding: 4, backgroundColor: '#1e1e1e', color: '#fff' }}>
          <Typography variant="h5" gutterBottom align="center">
            儲值確認單
          </Typography>
          <Divider sx={{ mb: 2, borderColor: '#555' }} />
          
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" color="textSecondary">
                訂單編號
              </Typography>
              <Typography variant="body1">
                {confirmRes.orderId}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" color="textSecondary">
                交易編號
              </Typography>
              <Typography variant="body1">
                {confirmRes.transactionId}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" color="textSecondary">
                支付方式
              </Typography>
              <Typography variant="body1">
                {confirmRes.payInfo[0].method === 'CREDIT_CARD' ? '信用卡' : confirmRes.payInfo[0].method}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" color="textSecondary">
                付款金額
              </Typography>
              <Typography variant="body1">
                NT$ {confirmRes.payInfo.reduce((total, item) => total + item.amount, 0)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="textSecondary">
                信用卡號碼
              </Typography>
              <Typography variant="body1">
                {confirmRes.payInfo[0].maskedCreditCardNumber}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2, borderColor: '#555' }} />

          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Typography variant="body2" color="textSecondary">
              感謝您的使用！
            </Typography>
          </Box>
        </Paper>
      )}
    </Container>
  );
};

export default ConfirmPage;

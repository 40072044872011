// src/components/HamburgerMenu.js

import React, { useContext } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Avatar,
  Divider,
  Grid,
  Paper,
  Snackbar,
  Alert,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import PeopleIcon from '@mui/icons-material/People';
import ShareIcon from '@mui/icons-material/Share'; // 邀请好友图标
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import liff from '@line/liff'; // 引入 LINE LIFF SDK
import { LiffContext } from '../contexts/LiffContext';

const HamburgerMenu = () => {
  const [open, setOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('info');
  const { isLiffInitialized, liff, liffError, profile, information } = useContext(LiffContext);
  
  const navigate = useNavigate();

  const toggleDrawer = (state) => () => {
    setOpen(state);
  };

  const handleNavigation = (path) => () => {
    navigate(path);
    setOpen(false);
  };

  // 邀请好友的处理函数
  const handleInviteFriends = async () => {
    try {
      if (!liff.isLoggedIn()) {
        await liff.login();
      }

      // 获取当前房间的链接或邀请链接
      const shareUrl = `https://liff.line.me/2006324027-ELngnp3Z/invite?referrer=${profile.userId}`;
      const shareMessage = {
        type: 'text',
        text: `${profile.displayName} 邀請你一起玩遊戲！點擊連結加入：${shareUrl}`,
      };

      await liff.shareTargetPicker([shareMessage]);

      setSnackbarMessage('邀請已發送！');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error sharing message:', error);
      setSnackbarMessage('邀請發送失敗，請重試。');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleLogout = async () => {
    try {
        if (liff.isLoggedIn()) {
            await liff.logout(); // 登出用戶
        }
        liff.closeWindow(); // 關閉 LIFF 頁面
    } catch (error) {
        console.error('Logout failed:', error);
        // 您可以在這裡添加額外的錯誤處理或用戶通知
    }
  };

  const menuItems = [
    { text: '儲值頁面', path: '/topup', icon: <MonetizationOnIcon /> },
    { text: '戰鬥大廳', path: '/', icon: <SportsEsportsIcon /> },
    { text: '好友列表', path: '/friends', icon: <PeopleIcon /> }, // 修改為導航路徑
    { text: '邀請好友', icon: <ShareIcon />, action: handleInviteFriends }, // 邀请好友
    { text: '登出', icon: <LogoutIcon />, action: handleLogout }, 
  ];

  const drawerContent = (
    <Box
      sx={{ width: 250, backgroundColor: '#000', color: '#fff' }}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      {/* 用户信息区域 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '12px',
          backgroundColor: '#333',
          color: '#fff',
        }}
      >
        {profile ? (
          <Avatar
            src={profile.pictureUrl}
            alt={profile.displayName}
            sx={{ width: 60, height: 60, mb: 1 }}
          />
        ) : (
          <Avatar sx={{ width: 60, height: 60, mb: 1 }}>LE</Avatar>
        )}
        <Typography variant="subtitle1" noWrap>
          {profile ? profile.displayName : 'Lucky Egg'}
        </Typography>
        <Typography variant="body2" noWrap>
          {information?.email}
        </Typography>

        {/* 统计数据区域 */}
        <Box sx={{ width: '100%', mt: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{
                  padding: '8px',
                  textAlign: 'center',
                  backgroundColor: '#444',
                  color: '#fff',
                }}
              >
                <Typography variant="subtitle2">剩餘金幣</Typography>
                <Typography variant="h6">$ {information?.moneyAmount}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{
                  padding: '8px',
                  textAlign: 'center',
                  backgroundColor: '#444',
                  color: '#fff',
                }}
              >
                <Typography variant="subtitle2">皇帝方勝率</Typography>
                <Typography variant="h6">{information?.winRates.emperorWinRate}%</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{
                  padding: '8px',
                  textAlign: 'center',
                  backgroundColor: '#444',
                  color: '#fff',
                }}
              >
                <Typography variant="subtitle2">奴隸方勝率</Typography>
                <Typography variant="h6">{information?.winRates.slaveWinRate}%</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Divider sx={{ backgroundColor: '#555' }} />
      {/* 菜单选项 */}
      <List>
        {menuItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onClick={item.action ? item.action : handleNavigation(item.path)}
              sx={{
                '&:hover': {
                  backgroundColor: '#444',
                },
              }}
            >
              <ListItemIcon sx={{ color: '#fff' }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider sx={{ backgroundColor: '#555' }} />
    </Box>
  );

  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: '#000' }}>
        <Toolbar
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              edge="start"
              onClick={toggleDrawer(true)}
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontFamily: '"Press Start 2P", cursive',
                letterSpacing: '0.1em',
                fontSize: '16px',
                whiteSpace: 'nowrap',
              }}
            >
              賭博默示錄 地下1800層
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        {drawerContent}
      </Drawer>
      {/* 提示信息 */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default HamburgerMenu;

// src/contexts/SignalRContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import {socketUrl} from '../socketUrl';

const SignalRContext = createContext();

export const useSignalR = () => {
  return useContext(SignalRContext);
};

export const SignalRProvider = ({ children }) => {
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    const conn = new HubConnectionBuilder()
      .withUrl(`${socketUrl}`) // 确保 URL 与后端匹配
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    setConnection(conn);

    conn.start()
      .then(() => {
        console.log('Connected to SignalR Hub');
      })
      .catch(err => console.error('SignalR Connection Error: ', err));

    return () => {
      if (conn) {
        conn.stop();
      }
    };
  }, []);

  return (
    <SignalRContext.Provider value={connection}>
      {children}
    </SignalRContext.Provider>
  );
};

// src/components/GlobalBackground.js

import React from 'react';
import { Box, keyframes } from '@mui/material';

// 定義漸變動畫
const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const GlobalBackground = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1, // 確保背景在最底層
        background: 'linear-gradient(135deg, #1f1f1f, #2c2c2c, #1f1f1f)',
        backgroundSize: '400% 400%',
        animation: `${gradientAnimation} 15s ease infinite`,
      }}
    />
  );
};

export default GlobalBackground;

// src/pages/TopUpPage.js

import React, { useEffect, useContext } from 'react';
import { Typography, Container, TextField, Button, Box, Card, CardContent, Alert } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { LiffContext } from '../contexts/LiffContext';
import { apiUrl } from '../apiUrl';

const TopUpPage = () => { // 修正 prop 名稱
  const { isLiffInitialized, liff, liffError, profile, information } = useContext(LiffContext);
  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required('儲值金額為必填項')
        .min(100, '最小儲值金額為 100 元')
        .max(100000, '最大儲值金額為 100,000 元'),
    }),
    onSubmit: async (values, { setSubmitting, resetForm, setStatus }) => {
      // 清除之前的狀態
      setStatus(null);

      // 將 districtNo 轉換為字串
      const districtNoStr = String(information.districtNo);

      // 定義錯誤訊息陣列
      const errors = [];

      // 驗證 districtNo
      if (!districtNoStr) {
        errors.push('區域編號 (districtNo) 為必填項');
      } else if (!/^\d{3}$/.test(districtNoStr)) {
        errors.push('區域編號 (districtNo) 必須為 3 碼數字');
      }

      // 驗證手機號碼
      if (!information.phoneNum) {
        errors.push('手機號碼為必填項');
      } else if (!/^\d{10}$/.test(information.phoneNum)) {
        errors.push('手機號碼必須為 10 碼數字');
      }

      // 驗證信箱格式
      if (!information.email) {
        errors.push('信箱為必填項');
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(information.email)) {
        errors.push('信箱格式不正確');
      }

      // 驗證其他必填屬性
      const requiredFields = [
        { field: information.confirmUrl, name: '確認 URL (confirmUrl)' },
        { field: information.cancelUrl, name: '取消 URL (cancelUrl)' },
        { field: information.city, name: '城市 (city)' },
        { field: information.districtName, name: '區域名稱 (districtName)' },
        { field: information.address, name: '詳細地址 (address)' },
        { field: information.nickName, name: '暱稱 (nickName)' },
        { field: information.name, name: '姓名 (name)' },
      ];

      requiredFields.forEach(({ field, name }) => {
        if (!field) {
          errors.push(`${name} 為必填項`);
        }
      });

      if (errors.length > 0) {
        setStatus({ error: errors });
        setSubmitting(false);
        return;
      }

      try {
        // 準備儲值請求資料，包括 userId
        const paymentRequest = {
          amount: Number(values.amount),
          orderId: `LuckyEgg-${Date.now()}`, 
          packages: [
            {
              id: "1",
              amount: Number(values.amount),
              products: [
                {
                  id: "1",
                  name: "儲值",
                  imageUrl:"https://lucky-egg.club/assets/src/static/logo.png",
                  quantity: 1,
                  price: Number(values.amount)
                }
              ]
            }
          ],
          redirectUrls: {
            confirmUrl: information.confirmUrl, // 替換為您的回調 URL
            cancelUrl: information.cancelUrl // 替換為您的取消 URL
          },
        };

        // 發送儲值請求到後端 API
        const response = await axios.post(`${apiUrl}/linepay`, paymentRequest);
        if (response.status === 200) {
          window.location.href = response.data.source.info.paymentUrl.web;
          setStatus({ success: '儲值請求已成功發送，請完成支付。' });
          resetForm();
        } else {
          // 顯示後端返回的錯誤訊息
          setStatus({ error: response.data.errors});
        }
      } catch (error) {
        console.error('請求異常:', error);
        setStatus({ error: ['請洽工程師'] });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Container maxWidth="sm" sx={{ mt: 12 }}>
      <Card sx={{ backgroundColor: '#1e1e1e', color: '#fff', boxShadow: '0 4px 20px rgba(0,0,0,0.5)' }}> {/* 深色卡

件背景，增加陰影 */}
        <CardContent>
          <Typography variant="h4" gutterBottom align="center">
            儲值頁面
          </Typography>
          <Typography variant="body1" gutterBottom align="center">
            使用 LINE PAY 進行儲值
          </Typography>
          <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
            <TextField
              fullWidth
              id="amount"
              name="amount"
              label="儲值金額 (元)"
              type="number"
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
              margin="normal"
              InputProps={{
                inputProps: { min: 100, max: 100000 },
                sx: { color: '#fff' }, // 輸入文字顏色改為白色
              }}
              InputLabelProps={{
                sx: { color: '#fff' }, // 標籤顏色改為白色
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#555', // 輸入框邊框顏色
                  },
                  '&:hover fieldset': {
                    borderColor: '#777',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#999',
                  },
                },
                '& .MuiInputBase-input': {
                  color: '#fff', // 輸入文字顏色
                },
                '& .MuiFormLabel-root': {
                  color: '#fff', // 標籤顏色
                },
              }}
            />
            {/* 顯示 Formik 狀態的成功訊息 */}
            {formik.status && formik.status.success && (
              <Alert severity="success" sx={{ mt: 2, backgroundColor: '#444', color: '#fff' }}>
                {formik.status.success}
              </Alert>
            )}
            {/* 顯示 Formik 狀態的錯誤訊息 */}
            {formik.status && formik.status.error && (
              <Alert severity="error" sx={{ mt: 2, backgroundColor: '#444', color: '#fff' }}>
                {Array.isArray(formik.status.error) ? (
                  <ul style={{ margin: 0, paddingLeft: '20px' }}>
                    {formik.status.error.map((err, index) => (
                      <li key={index}>{err}</li>
                    ))}
                  </ul>
                ) : (
                  formik.status.error
                )}
              </Alert>
            )}
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={formik.isSubmitting}
              sx={{
                mt: 2,
                backgroundColor: '#555', // 按鈕背景顏色
                '&:hover': {
                  backgroundColor: '#666', // 按鈕懸停顏色
                },
              }}
            >
              儲值
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default TopUpPage;

// src/contexts/LiffContext.js

import React, { createContext, useEffect, useState } from 'react';
import liff from '@line/liff';
import axios from 'axios';
import { apiUrl } from '../apiUrl';

export const LiffContext = createContext();

const LiffProvider = ({ children }) => {
  const [isLiffInitialized, setIsLiffInitialized] = useState(false);
  const [liffError, setLiffError] = useState(null);
  const [profile, setProfile] = useState(null);
  const [information, setInformation] = useState(null);

  useEffect(() => {
    const initializeLiff = async () => {
      try {
        await liff.init({
          liffId: '2006324027-ELngnp3Z', // 替换为您的 LIFF ID
          withLoginOnExternalBrowser: true,
          scope: ['profile', 'chat_message.write']
        });
        setIsLiffInitialized(true);

        if (!liff.isInClient()) {
          alert('請透過LINE 瀏覽器開啟')
          window.location.replace('https://lucky-egg.club/'); // 替换为您的重定向 URL
          return;
        }

        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          const userProfile = await liff.getProfile();
          setProfile(userProfile);
          localStorage.setItem('userId', userProfile.userId);
          // 获取用户信息
          const response = await axios.get(`${apiUrl}/information`);
          if (response.status === 200) {
            setInformation(response.data.source);
          }
        }
      } catch (error) {
        console.error('LIFF 初始化失败', error);
        setLiffError(error);
      }
    };

    initializeLiff();
  }, []);

  return (
    <LiffContext.Provider value={{ isLiffInitialized, liff, liffError, profile, information }}>
      {children}
    </LiffContext.Provider>
  );
};

export default LiffProvider;
